'use client'

import CustomImageLoader from './CustomImageLoader'

const Section = ({
  index,
  imageSrc,
  heading,
  headingClassName,
  text,
  title,
  textClassName,
  children,
  imageStyle,
  textContainer,
  idx,
}) => {
  const isImageOnLeft = index % 2 === 0

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  // Preprocess text to replace <br/> with <p> with padding
  const paragraphs = text.split('<br/>').map((paragraph, index) => (
    <p key={index} className={` ${textClassName || ''}`}>
      {paragraph}
    </p>
  ))

  return (
    <div
      className={`flex flex-col gap-4 w-full md:flex-row p-2 md:px-12 bg-bgColor text-textColor dark:bg-textColor dark:text-bgColor lg:flex ${
        isImageOnLeft ? 'md:flex-row-reverse' : ''
      }`}
    >
      <CustomImageLoader
        src={imageSrc}
        title={title}
        alt={`Bild om ${idx}`}
        className={`rounded-md shadow-md my-4`}
        imageStyle={imageStyle}
        width={300}
        height={300}
        decoding='async'
        loading='lazy'
        onLoad={handleImageLoad}
      />
      <div className={`md:w-full p-4 md:pl-8 ${textContainer || ''}`}>
        <h2
          className={`flex justify-center font-bold mb-4 text-2xl ${
            headingClassName || ''
          }`}
        >
          {heading}
        </h2>
        {paragraphs}
        {children}
      </div>
    </div>
  )
}

export default Section

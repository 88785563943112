'use client'

import React, { useState } from 'react'
import Image from 'next/image'

const CustomImageLoader = ({ src, width, height, alt, title }) => {
  const [loading, setLoading] = useState(true)

  const handleImageLoad = () => {
    setLoading(false)
  }

  const handleImageError = () => {
    setLoading(false)
    // Handle image load error if needed
  }

  return (
    <div className='relative h-full w-full'>
      {loading && (
        <div
          className='animate-pulse'
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f3f3f3',
            objectFit: 'cover', // Customize as needed
            borderRadius: '4px',
            filter: 'grayscale(50%)',
          }}
        >
          <p className='text-black font-extrabold text-lg'>Loading...</p>
        </div>
      )}
      <Image
        className='object-cover rounded h-96 w-full'
        src={src}
        width={width}
        height={height}
        alt={alt}
        title={title}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </div>
  )
}
export default CustomImageLoader
